/*
 * CUSTOM STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance: styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */



@include breakpoint( medium ) { /* min-width: 520px */
    
}

@include breakpoint( large ) { /* min-width: 960px */

}

@include breakpoint( xlarge ) { /* min-width: 1200px */

}

@include breakpoint( xxlarge ) { /* min-width: 1600px */

}