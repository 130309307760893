//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Meter
//  24. Off-canvas
//  25. Orbit
//  26. Pagination
//  27. Progress Bar
//  28. Responsive Embed
//  29. Reveal
//  30. Slider
//  31. Switch
//  32. Table
//  33. Tabs
//  34. Thumbnail
//  35. Title Bar
//  36. Tooltip
//  37. Top Bar
//  
//  Custom
//  38. Alert boxes (Foundation 5)

@import '../../../../node_modules/foundation-sites/scss/util/util';

// 1. Global
// ---------

$global-font-size: 62.5%;
// $global-width: rem-calc(1200);
// $global-lineheight: 1.5;
$foundation-palette: (
    primary: #2199e8,
    secondary: #767676,
    success: #00a65a,
    warning: #f39c12,
    alert: #dd4b39,
);
// $light-gray: #e6e6e6;
// $medium-gray: #cacaca;
// $dark-gray: #8a8a8a;
// $black: #000000;
// $white: #ffffff;
// $body-background: $white;
// $body-font-color: $black;
// $body-font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
// $body-antialiased: true;
// $global-margin: 1rem;
// $global-padding: 1rem;
// $global-weight-normal: normal;
// $global-weight-bold: bold;
// $global-radius: 0;
// $global-text-direction: ltr;
// $global-flexbox: false;
// $print-transparent-backgrounds: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
    small: 0,
    medium: 520px,
    large: 960px,
    xlarge: 1200px,
    xxlarge: 1600px,
);
// $print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge);

// 3. The Grid
// -----------

// $grid-row-width: $global-width;
// $grid-column-count: 12;
// $grid-column-gutter: (
//   small: 20px,
//   medium: 30px,
// );
// $grid-column-align-edge: true;
// $block-grid-max: 8;

// 4. Base Typography
// ------------------

// $header-font-family: $body-font-family;
// $header-font-weight: $global-weight-normal;
// $header-font-style: normal;
// $font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: $primary-color;
$header-lineheight: 1.2;
$header-margin-bottom: 1.5rem;
// $header-styles: (
//   small: (
//     'h1': ('font-size': 24),
//     'h2': ('font-size': 20),
//     'h3': ('font-size': 19),
//     'h4': ('font-size': 18),
//     'h5': ('font-size': 17),
//     'h6': ('font-size': 16),
//   ),
//   medium: (
//     'h1': ('font-size': 48),
//     'h2': ('font-size': 40),
//     'h3': ('font-size': 31),
//     'h4': ('font-size': 25),
//     'h5': ('font-size': 20),
//     'h6': ('font-size': 16),
//   ),
// );
// $header-text-rendering: optimizeLegibility;
// $small-font-size: 80%;
// $header-small-font-color: $medium-gray;
// $paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 2rem;
// $paragraph-text-rendering: optimizeLegibility;
// $code-color: $black;
// $code-font-family: $font-family-monospace;
// $code-font-weight: $global-weight-normal;
// $code-background: $light-gray;
// $code-border: 1px solid $medium-gray;
// $code-padding: rem-calc(2 5 1);
// $anchor-color: $primary-color;
// $anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
// $anchor-text-decoration: none;
// $anchor-text-decoration-hover: none;
// $hr-width: $global-width;
// $hr-border: 1px solid $medium-gray;
// $hr-margin: rem-calc(20) auto;
// $list-lineheight: $paragraph-lineheight;
// $list-margin-bottom: $paragraph-margin-bottom;
// $list-style-type: disc;
// $list-style-position: outside;
// $list-side-margin: 1.25rem;
// $list-nested-side-margin: 1.25rem;
// $defnlist-margin-bottom: 1rem;
// $defnlist-term-weight: $global-weight-bold;
// $defnlist-term-margin-bottom: 0.3rem;
// $blockquote-color: $dark-gray;
// $blockquote-padding: rem-calc(9 20 0 19);
// $blockquote-border: 1px solid $medium-gray;
// $cite-font-size: rem-calc(13);
// $cite-color: $dark-gray;
// $cite-pseudo-content: '\2014 \0020';
// $keystroke-font: $font-family-monospace;
// $keystroke-color: $black;
// $keystroke-background: $light-gray;
// $keystroke-padding: rem-calc(2 4 0);
// $keystroke-radius: $global-radius;
// $abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

// $lead-font-size: $global-font-size * 1.25;
// $lead-lineheight: 1.6;
// $subheader-lineheight: 1.4;
// $subheader-color: $dark-gray;
// $subheader-font-weight: $global-weight-normal;
// $subheader-margin-top: 0.2rem;
// $subheader-margin-bottom: 0.5rem;
// $stat-font-size: 2.5rem;

// 6. Abide
// --------

// $abide-inputs: true;
// $abide-labels: true;
// $input-background-invalid: get-color(alert);
// $form-label-color-invalid: get-color(alert);
// $input-error-color: get-color(alert);
// $input-error-font-size: rem-calc(12);
// $input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

// $accordion-background: $white;
// $accordion-plusminus: true;
// $accordion-title-font-size: rem-calc(12);
// $accordion-item-color: $primary-color;
// $accordion-item-background-hover: $light-gray;
// $accordion-item-padding: 1.25rem 1rem;
// $accordion-content-background: $white;
// $accordion-content-border: 1px solid $light-gray;
// $accordion-content-color: $body-font-color;
// $accordion-content-padding: 1rem;

// 8. Accordion Menu
// -----------------

// $accordionmenu-arrows: true;
// $accordionmenu-arrow-color: $primary-color;
// $accordionmenu-arrow-size: 6px;

// 9. Badge
// --------

// $badge-background: $primary-color;
// $badge-color: $white;
// $badge-color-alt: $black;
// $badge-palette: $foundation-palette;
// $badge-padding: 0.3em;
// $badge-minwidth: 2.1em;
// $badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

// $breadcrumbs-margin: 0 0 $global-margin 0;
// $breadcrumbs-item-font-size: rem-calc(11);
// $breadcrumbs-item-color: $primary-color;
// $breadcrumbs-item-color-current: $black;
// $breadcrumbs-item-color-disabled: $medium-gray;
// $breadcrumbs-item-margin: 0.75rem;
// $breadcrumbs-item-uppercase: true;
// $breadcrumbs-item-slash: true;

// 11. Button
// ----------

// $button-padding: 0.85em 1em;
// $button-margin: 0 0 $global-margin 0;
// $button-fill: solid;
// $button-background: $primary-color;
// $button-background-hover: scale-color($button-background, $lightness: -15%);
// $button-color: $white;
// $button-color-alt: $black;
// $button-radius: $global-radius;
$button-sizes: (
    tiny: 1.2rem,
    small: 1.4rem,
    default: 1.6rem,
    large: 1.8rem,
);
// $button-palette: $foundation-palette;
// $button-opacity-disabled: 0.25;
// $button-background-hover-lightness: -20%;
// $button-hollow-hover-lightness: -50%;
// $button-transition: background-color 0.25s ease-out, color 0.25s ease-out;

// 12. Button Group
// ----------------

// $buttongroup-margin: 1rem;
// $buttongroup-spacing: 1px;
// $buttongroup-child-selector: '.button';
// $buttongroup-expand-max: 6;
// $buttongroup-radius-on-each: true;

// 13. Callout
// -----------

// $callout-background: $white;
// $callout-background-fade: 85%;
// $callout-border: 1px solid rgba($black, 0.25);
// $callout-margin: 0 0 1rem 0;
// $callout-padding: 1rem;
// $callout-font-color: $body-font-color;
// $callout-font-color-alt: $body-background;
// $callout-radius: $global-radius;
// $callout-link-tint: 30%;

// 14. Card
// --------

// $card-background: $white;
// $card-font-color: $body-font-color;
// $card-divider-background: $light-gray;
// $card-border: 1px solid $light-gray;
// $card-shadow: none;
// $card-border-radius: $global-radius;
// $card-padding: $global-padding;
// $card-margin: $global-margin;

// 15. Close Button
// ----------------

// $closebutton-position: right top;
// $closebutton-offset-horizontal: (
//   small: 0.66rem,
//   medium: 1rem,
// );
// $closebutton-offset-vertical: (
//   small: 0.33em,
//   medium: 0.5rem,
// );
// $closebutton-size: (
//   small: 1.5em,
//   medium: 2em,
// );
// $closebutton-lineheight: 1;
// $closebutton-color: $dark-gray;
// $closebutton-color-hover: $black;

// 16. Drilldown
// -------------

// $drilldown-transition: transform 0.15s linear;
// $drilldown-arrows: true;
// $drilldown-arrow-color: $primary-color;
// $drilldown-arrow-size: 6px;
// $drilldown-background: $white;

// 17. Dropdown
// ------------

// $dropdown-padding: 1rem;
// $dropdown-background: $body-background;
// $dropdown-border: 1px solid $medium-gray;
// $dropdown-font-size: 1rem;
// $dropdown-width: 300px;
// $dropdown-radius: $global-radius;
// $dropdown-sizes: (
//   tiny: 100px,
//   small: 200px,
//   large: 400px,
// );

// 18. Dropdown Menu
// -----------------

// $dropdownmenu-arrows: true;
// $dropdownmenu-arrow-color: $anchor-color;
// $dropdownmenu-arrow-size: 6px;
// $dropdownmenu-min-width: 200px;
// $dropdownmenu-background: $white;
// $dropdownmenu-border: 1px solid $medium-gray;

// 19. Forms
// ---------

// $fieldset-border: 1px solid $medium-gray;
// $fieldset-padding: rem-calc(20);
// $fieldset-margin: rem-calc(18 0);
// $legend-padding: rem-calc(0 3);
// $form-spacing: rem-calc(16);
// $helptext-color: $black;
// $helptext-font-size: rem-calc(13);
// $helptext-font-style: italic;
// $input-prefix-color: $black;
// $input-prefix-background: $light-gray;
// $input-prefix-border: 1px solid $medium-gray;
// $input-prefix-padding: 1rem;
// $form-label-color: $black;
// $form-label-font-size: rem-calc(14);
// $form-label-font-weight: $global-weight-normal;
// $form-label-line-height: 1.8;
// $select-background: $white;
// $select-triangle-color: $dark-gray;
// $select-radius: $global-radius;
// $input-color: $black;
// $input-placeholder-color: $medium-gray;
// $input-font-family: inherit;
// $input-font-size: rem-calc(16);
// $input-font-weight: $global-weight-normal;
// $input-background: $white;
// $input-background-focus: $white;
// $input-background-disabled: $light-gray;
// $input-border: 1px solid $medium-gray;
// $input-border-focus: 1px solid $dark-gray;
// $input-shadow: inset 0 1px 2px rgba($black, 0.1);
// $input-shadow-focus: 0 0 5px $medium-gray;
// $input-cursor-disabled: not-allowed;
// $input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
// $input-number-spinners: true;
// $input-radius: $global-radius;
// $form-button-radius: $global-radius;

// 20. Label
// ---------

// $label-background: $primary-color;
// $label-color: $white;
// $label-color-alt: $black;
// $label-palette: $foundation-palette;
// $label-font-size: 0.8rem;
// $label-padding: 0.33333rem 0.5rem;
// $label-radius: $global-radius;

// 21. Media Object
// ----------------

// $mediaobject-margin-bottom: $global-margin;
// $mediaobject-section-padding: $global-padding;
// $mediaobject-image-width-stacked: 100%;

// 22. Menu
// --------

// $menu-margin: 0;
// $menu-margin-nested: 1rem;
// $menu-item-padding: 0.7rem 1rem;
// $menu-item-color-active: $white;
// $menu-item-background-active: get-color(primary);
// $menu-icon-spacing: 0.25rem;
// $menu-item-background-hover: $light-gray;
// $menu-border: $light-gray;

// 23. Meter
// ---------

// $meter-height: 1rem;
// $meter-radius: $global-radius;
// $meter-background: $medium-gray;
// $meter-fill-good: $success-color;
// $meter-fill-medium: $warning-color;
// $meter-fill-bad: $alert-color;

// 24. Off-canvas
// --------------

// $offcanvas-size: 250px;
// $offcanvas-vertical-size: 250px;
// $offcanvas-background: $light-gray;
// $offcanvas-shadow: 0 0 10px rgba($black, 0.7);
// $offcanvas-push-zindex: 1;
// $offcanvas-overlap-zindex: 10;
// $offcanvas-reveal-zindex: 1;
// $offcanvas-transition-length: 0.5s;
// $offcanvas-transition-timing: ease;
// $offcanvas-fixed-reveal: true;
// $offcanvas-exit-background: rgba($white, 0.25);
// $maincontent-class: 'off-canvas-content';

// 25. Orbit
// ---------

// $orbit-bullet-background: $medium-gray;
// $orbit-bullet-background-active: $dark-gray;
// $orbit-bullet-diameter: 1.2rem;
// $orbit-bullet-margin: 0.1rem;
// $orbit-bullet-margin-top: 0.8rem;
// $orbit-bullet-margin-bottom: 0.8rem;
// $orbit-caption-background: rgba($black, 0.5);
// $orbit-caption-padding: 1rem;
// $orbit-control-background-hover: rgba($black, 0.5);
// $orbit-control-padding: 1rem;
// $orbit-control-zindex: 10;

// 26. Pagination
// --------------

// $pagination-font-size: rem-calc(14);
// $pagination-margin-bottom: $global-margin;
// $pagination-item-color: $black;
// $pagination-item-padding: rem-calc(3 10);
// $pagination-item-spacing: rem-calc(1);
// $pagination-radius: $global-radius;
// $pagination-item-background-hover: $light-gray;
// $pagination-item-background-current: $primary-color;
// $pagination-item-color-current: $white;
// $pagination-item-color-disabled: $medium-gray;
// $pagination-ellipsis-color: $black;
// $pagination-mobile-items: false;
// $pagination-mobile-current-item: false;
// $pagination-arrows: true;

// 27. Progress Bar
// ----------------

// $progress-height: 1rem;
// $progress-background: $medium-gray;
// $progress-margin-bottom: $global-margin;
// $progress-meter-background: $primary-color;
// $progress-radius: $global-radius;

// 28. Responsive Embed
// --------------------

// $responsive-embed-margin-bottom: rem-calc(16);
// $responsive-embed-ratios: (
//   default: 4 by 3,
//   widescreen: 16 by 9,
// );

// 29. Reveal
// ----------

// $reveal-background: $white;
// $reveal-width: 600px;
// $reveal-max-width: $global-width;
// $reveal-padding: $global-padding;
// $reveal-border: 1px solid $medium-gray;
// $reveal-radius: $global-radius;
// $reveal-zindex: 1005;
// $reveal-overlay-background: rgba($black, 0.45);

// 30. Slider
// ----------

// $slider-width-vertical: 0.5rem;
// $slider-transition: all 0.2s ease-in-out;
// $slider-height: 0.5rem;
// $slider-background: $light-gray;
// $slider-fill-background: $medium-gray;
// $slider-handle-height: 1.4rem;
// $slider-handle-width: 1.4rem;
// $slider-handle-background: $primary-color;
// $slider-opacity-disabled: 0.25;
// $slider-radius: $global-radius;

// 31. Switch
// ----------

// $switch-background: $medium-gray;
// $switch-background-active: $primary-color;
// $switch-height: 2rem;
$switch-height-tiny: 1.6rem;
$switch-height-small: 1.8rem;
$switch-height-large: 2.6rem;
// $switch-radius: $global-radius;
// $switch-margin: $global-margin;
// $switch-paddle-background: $white;
$switch-paddle-offset: 0.3rem;
// $switch-paddle-radius: $global-radius;
// $switch-paddle-transition: all 0.25s ease-out;

// 32. Table
// ---------

// $table-background: $white;
// $table-color-scale: 5%;
// $table-border: 1px solid smart-scale($table-background, $table-color-scale);
// $table-padding: rem-calc(8 10 10);
// $table-hover-scale: 2%;
// $table-row-hover: darken($table-background, $table-hover-scale);
// $table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
// $table-is-striped: true;
// $table-striped-background: smart-scale($table-background, $table-color-scale);
// $table-stripe: even;
// $table-head-background: smart-scale($table-background, $table-color-scale / 2);
// $table-head-row-hover: darken($table-head-background, $table-hover-scale);
// $table-foot-background: smart-scale($table-background, $table-color-scale);
// $table-foot-row-hover: darken($table-foot-background, $table-hover-scale);
// $table-head-font-color: $body-font-color;
// $table-foot-font-color: $body-font-color;
// $show-header-for-stacked: false;

// 33. Tabs
// --------

// $tab-margin: 0;
// $tab-background: $white;
// $tab-color: $primary-color;
// $tab-background-active: $light-gray;
// $tab-active-color: $primary-color;
// $tab-item-font-size: rem-calc(12);
// $tab-item-background-hover: $white;
// $tab-item-padding: 1.25rem 1.5rem;
// $tab-expand-max: 6;
// $tab-content-background: $white;
// $tab-content-border: $light-gray;
// $tab-content-color: $body-font-color;
// $tab-content-padding: 1rem;

// 34. Thumbnail
// -------------

// $thumbnail-border: solid 4px $white;
// $thumbnail-margin-bottom: $global-margin;
// $thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
// $thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
// $thumbnail-transition: box-shadow 200ms ease-out;
// $thumbnail-radius: $global-radius;

// 35. Title Bar
// -------------

// $titlebar-background: $black;
// $titlebar-color: $white;
// $titlebar-padding: 0.5rem;
// $titlebar-text-font-weight: bold;
// $titlebar-icon-color: $white;
// $titlebar-icon-color-hover: $medium-gray;
// $titlebar-icon-spacing: 0.25rem;

// 36. Tooltip
// -----------

// $has-tip-font-weight: $global-weight-bold;
// $has-tip-border-bottom: dotted 1px $dark-gray;
// $tooltip-background-color: $black;
// $tooltip-color: $white;
// $tooltip-padding: 0.75rem;
// $tooltip-font-size: $small-font-size;
// $tooltip-pip-width: 0.75rem;
// $tooltip-pip-height: $tooltip-pip-width * 0.866;
// $tooltip-radius: $global-radius;

// 37. Top Bar
// -----------

// $topbar-padding: 0.5rem;
// $topbar-background: $light-gray;
// $topbar-submenu-background: $topbar-background;
// $topbar-title-spacing: 0.5rem 1rem 0.5rem 0;
// $topbar-input-width: 200px;
// $topbar-unstack-breakpoint: medium;

//  38. Alert boxes (Foundation 5)
// -----------
 
$alert-padding-top: rem-calc(14);
$alert-padding-default-float: $alert-padding-top;
$alert-padding-opposite-direction: $alert-padding-top + rem-calc(10);
$alert-padding-bottom: $alert-padding-top;

$alert-font-weight: normal;
$alert-font-size: rem-calc(14);
$alert-font-color: #ffffff;
$alert-font-color-alt: scale-color($secondary-color, $lightness: -66%);

$alert-function-factor: -14%;

$alert-border-style: solid;
$alert-border-width: 1px;
$alert-border-color: scale-color($primary-color, $lightness: $alert-function-factor);
$alert-bottom-margin: rem-calc(20);

$alert-close-color: #ffffff;
$alert-close-top: 50%;
$alert-close-position: rem-calc(4);
$alert-close-font-size: rem-calc(22);
$alert-close-opacity: 0.8;
$alert-close-opacity-hover: 1;
$alert-close-padding: 0 6px 4px;
$alert-close-background: inherit;

$alert-radius: .5rem;

$alert-transition-speed: 300ms;
$alert-transition-ease: ease-out;