/*
 * GENERAL CLASSES 
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 */

/* Default browser style override */
$body-font-size: rem-calc(16);

body{font-size: $body-font-size;}
p{font-size: $body-font-size;}
ul, ol, dl{font-size: $body-font-size;}
select {appearance: none !important;}

/* Alert boxes */
.alert-box{
    @include alert;
    .close{ @include alert-close; }
    &.radius{border-radius:$alert-radius;}
    &.primary{ @include alert-style($primary-color); }
    &.secondary{ @include alert-style($secondary-color); }
    &.success{ @include alert-style($success-color); }
    &.alert{ @include alert-style($alert-color); }    
    &.warning{ @include alert-style($warning-color); } 
    &.alert-close{opacity:0;}
    ul{margin-bottom:0;}
    a{
        color:$white; text-decoration:underline;
        &:hover{text-decoration:none;}
    }
}

@include breakpoint( medium ) { /* min-width: 520px */
    
}

@include breakpoint( large ) { /* min-width: 960px */

}

@include breakpoint( xlarge ) { /* min-width: 1200px */

}

@include breakpoint( xxlarge ) { /* min-width: 1600px */

}
