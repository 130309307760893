@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=32.5em&large=60em&xlarge=75em&xxlarge=100em"; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 120rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1rem;
    margin-left: -1rem; }
    @media print, screen and (min-width: 32.5em) {
      .row .row {
        margin-right: -1.5rem;
        margin-left: -1.5rem; } }
    @media print, screen and (min-width: 60em) {
      .row .row {
        margin-right: -1.5rem;
        margin-left: -1.5rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -1.5rem;
        margin-left: -1.5rem; } }
    @media screen and (min-width: 100em) {
      .row .row {
        margin-right: -1.5rem;
        margin-left: -1.5rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1rem;
  padding-left: 1rem; }
  @media print, screen and (min-width: 32.5em) {
    .column, .columns {
      padding-right: 1.5rem;
      padding-left: 1.5rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.3333333333%; }

.small-push-1 {
  position: relative;
  left: 8.3333333333%; }

.small-pull-1 {
  position: relative;
  left: -8.3333333333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.6666666667%; }

.small-push-2 {
  position: relative;
  left: 16.6666666667%; }

.small-pull-2 {
  position: relative;
  left: -16.6666666667%; }

.small-offset-1 {
  margin-left: 8.3333333333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.6666666667%; }

.small-4 {
  width: 33.3333333333%; }

.small-push-4 {
  position: relative;
  left: 33.3333333333%; }

.small-pull-4 {
  position: relative;
  left: -33.3333333333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.6666666667%; }

.small-push-5 {
  position: relative;
  left: 41.6666666667%; }

.small-pull-5 {
  position: relative;
  left: -41.6666666667%; }

.small-offset-4 {
  margin-left: 33.3333333333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.6666666667%; }

.small-7 {
  width: 58.3333333333%; }

.small-push-7 {
  position: relative;
  left: 58.3333333333%; }

.small-pull-7 {
  position: relative;
  left: -58.3333333333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.6666666667%; }

.small-push-8 {
  position: relative;
  left: 66.6666666667%; }

.small-pull-8 {
  position: relative;
  left: -66.6666666667%; }

.small-offset-7 {
  margin-left: 58.3333333333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.6666666667%; }

.small-10 {
  width: 83.3333333333%; }

.small-push-10 {
  position: relative;
  left: 83.3333333333%; }

.small-pull-10 {
  position: relative;
  left: -83.3333333333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.6666666667%; }

.small-push-11 {
  position: relative;
  left: 91.6666666667%; }

.small-pull-11 {
  position: relative;
  left: -91.6666666667%; }

.small-offset-10 {
  margin-left: 83.3333333333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.6666666667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333333333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6666666667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857142857%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1rem;
  padding-left: 1rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 32.5em) {
  .medium-1 {
    width: 8.3333333333%; }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.6666666667%; }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .medium-offset-1 {
    margin-left: 8.3333333333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.6666666667%; }
  .medium-4 {
    width: 33.3333333333%; }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.6666666667%; }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .medium-offset-4 {
    margin-left: 33.3333333333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.6666666667%; }
  .medium-7 {
    width: 58.3333333333%; }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.6666666667%; }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .medium-offset-7 {
    margin-left: 58.3333333333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.6666666667%; }
  .medium-10 {
    width: 83.3333333333%; }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.6666666667%; }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .medium-offset-10 {
    margin-left: 83.3333333333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.6666666667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 60em) {
  .large-1 {
    width: 8.3333333333%; }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .large-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.6666666667%; }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .large-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .large-offset-1 {
    margin-left: 8.3333333333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.6666666667%; }
  .large-4 {
    width: 33.3333333333%; }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .large-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.6666666667%; }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .large-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .large-offset-4 {
    margin-left: 33.3333333333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.6666666667%; }
  .large-7 {
    width: 58.3333333333%; }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .large-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.6666666667%; }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .large-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .large-offset-7 {
    margin-left: 58.3333333333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.6666666667%; }
  .large-10 {
    width: 83.3333333333%; }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .large-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.6666666667%; }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .large-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .large-offset-10 {
    margin-left: 83.3333333333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.6666666667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.3333333333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.6666666667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%; }
  .xlarge-4 {
    width: 33.3333333333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.6666666667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%; }
  .xlarge-7 {
    width: 58.3333333333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.6666666667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%; }
  .xlarge-10 {
    width: 83.3333333333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.6666666667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 100em) {
  .xxlarge-1 {
    width: 8.3333333333%; }
  .xxlarge-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 16.6666666667%; }
  .xxlarge-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%; }
  .xxlarge-3 {
    width: 25%; }
  .xxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%; }
  .xxlarge-4 {
    width: 33.3333333333%; }
  .xxlarge-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    width: 41.6666666667%; }
  .xxlarge-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%; }
  .xxlarge-6 {
    width: 50%; }
  .xxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%; }
  .xxlarge-7 {
    width: 58.3333333333%; }
  .xxlarge-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    width: 66.6666666667%; }
  .xxlarge-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%; }
  .xxlarge-9 {
    width: 75%; }
  .xxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%; }
  .xxlarge-10 {
    width: 83.3333333333%; }
  .xxlarge-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    width: 91.6666666667%; }
  .xxlarge-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%; }
  .xxlarge-12 {
    width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 32.5em) {
    .column-block {
      margin-bottom: 3rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 2rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #2199e8;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 2.4rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem; }

h2 {
  font-size: 2rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem; }

h3 {
  font-size: 1.9rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem; }

h4 {
  font-size: 1.8rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem; }

h5 {
  font-size: 1.7rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem; }

h6 {
  font-size: 1.6rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem; }

@media print, screen and (min-width: 32.5em) {
  h1 {
    font-size: 4.8rem; }
  h2 {
    font-size: 4rem; }
  h3 {
    font-size: 3.1rem; }
  h4 {
    font-size: 2.5rem; }
  h5 {
    font-size: 2rem; }
  h6 {
    font-size: 1.6rem; } }

a {
  line-height: inherit;
  color: #2199e8;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1585cf; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 120rem;
  height: 0;
  margin: 2rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 2rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 2rem;
  padding: 0.9rem 2rem 0 1.9rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 1.3rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #0a0a0a;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.2rem 0.5rem 0.1rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.2rem 0.4rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 78.125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 32.5em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 60em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 100em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #2199e8;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #1583cc;
    color: #fefefe; }
  .button.tiny {
    font-size: 1.2rem; }
  .button.small {
    font-size: 1.4rem; }
  .button.large {
    font-size: 1.8rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #2199e8;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #147cc0;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #00a65a;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #008548;
      color: #0a0a0a; }
  .button.warning {
    background-color: #f39c12;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #c77e0a;
      color: #0a0a0a; }
  .button.alert {
    background-color: #dd4b39;
    color: #0a0a0a; }
    .button.alert:hover, .button.alert:focus {
      background-color: #be3221;
      color: #0a0a0a; }
  .button.hollow {
    border: 1px solid #2199e8;
    color: #2199e8; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c4d78;
      color: #0c4d78; }
    .button.hollow.primary {
      border: 1px solid #2199e8;
      color: #2199e8; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c4d78;
        color: #0c4d78; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #00a65a;
      color: #00a65a; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #00532d;
        color: #00532d; }
    .button.hollow.warning {
      border: 1px solid #f39c12;
      color: #f39c12; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #7c4f06;
        color: #7c4f06; }
    .button.hollow.alert {
      border: 1px solid #dd4b39;
      color: #dd4b39; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #771f14;
        color: #771f14; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #2199e8;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #2199e8;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #00a65a;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #f39c12;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #dd4b39;
        color: #0a0a0a; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #def0fc;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #cbffe7;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fdf0db;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #fae4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

/*
* CUSTOM MIXINS
*
* Some things in CSS are a bit tedious to write, especially with CSS3 and the many vendor prefixes that exist. 
* A mixin lets you make groups of CSS declarations that you want to reuse throughout your site. 
* You can even pass in values to make your mixin more flexible. 
*/
/* ALERT BOXES (FROM FOUNDATION 5) */
/*
 * GENERAL CLASSES 
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 */
/* Default browser style override */
body {
  font-size: 1.6rem; }

p {
  font-size: 1.6rem; }

ul, ol, dl {
  font-size: 1.6rem; }

select {
  appearance: none !important; }

/* Alert boxes */
.alert-box {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-weight: normal;
  margin-bottom: 2rem;
  position: relative;
  padding: 1.4rem 2.4rem 1.4rem 1.4rem;
  font-size: 1.4rem;
  transition: opacity 300ms ease-out;
  background-color: #2199e8;
  border-color: #1585cf;
  color: #ffffff; }
  .alert-box .close {
    font-size: 2.2rem;
    padding: 0 6px 4px;
    line-height: .9;
    position: absolute;
    top: 50%;
    margin-top: -1.1rem;
    right: 0.4rem;
    color: #ffffff;
    opacity: 0.8;
    background: inherit; }
    .alert-box .close:hover, .alert-box .close:focus {
      opacity: 1; }
  .alert-box.radius {
    border-radius: 0.5rem; }
  .alert-box.primary {
    background-color: #2199e8;
    border-color: #1585cf;
    color: #ffffff; }
  .alert-box.secondary {
    background-color: #767676;
    border-color: #656565;
    color: #ffffff; }
  .alert-box.success {
    background-color: #00a65a;
    border-color: #008f4d;
    color: #ffffff; }
  .alert-box.alert {
    background-color: #dd4b39;
    border-color: #cc3623;
    color: #ffffff; }
  .alert-box.warning {
    background-color: #f39c12;
    border-color: #d6870b;
    color: #ffffff; }
  .alert-box.alert-close {
    opacity: 0; }
  .alert-box ul {
    margin-bottom: 0; }
  .alert-box a {
    color: #fefefe;
    text-decoration: underline; }
    .alert-box a:hover {
      text-decoration: none; }

@media print, screen and (min-width: 32.5em) {
  /* min-width: 520px */ }

@media print, screen and (min-width: 60em) {
  /* min-width: 960px */ }

@media screen and (min-width: 75em) {
  /* min-width: 1200px */ }

@media screen and (min-width: 100em) {
  /* min-width: 1600px */ }

/*
 * OVERWRITES 
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */
/* zf Switch */
.switch-active, .switch-inactive {
  width: 55%;
  text-align: center;
  display: inline-block; }

.switch-active {
  left: 0; }

.switch-inactive {
  right: 0; }

@media print, screen and (min-width: 32.5em) {
  /* min-width: 520px */ }

@media print, screen and (min-width: 60em) {
  /* min-width: 960px */ }

@media screen and (min-width: 75em) {
  /* min-width: 1200px */ }

@media screen and (min-width: 100em) {
  /* min-width: 1600px */ }

/*
 * CUSTOM STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance: styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */
@media print, screen and (min-width: 32.5em) {
  /* min-width: 520px */ }

@media print, screen and (min-width: 60em) {
  /* min-width: 960px */ }

@media screen and (min-width: 75em) {
  /* min-width: 1200px */ }

@media screen and (min-width: 100em) {
  /* min-width: 1600px */ }
